.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.carousel {
  width: 100%;
  max-width: 800px;
  height: 300px;
}

.carousel .slick-slide {
  transition: all 50s ease;
}

.language-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  box-shadow: none !important;
}

.language-card h1 {
  font-size: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.auth-buttons {
  position: fixed;
  top: 10px;
  right: 10px;
}

.auth-buttons Button {
  margin-left: 10px;
}

